import * as React from "react";
import Redirect from "../../../components/redirect";
//--------------------------------------------------

  const url = "https://yucolab-my.sharepoint.com/:f:/p/jimz_az/EiiR1F_FyxZEu2oTwCdAE7QBtljAzx_cc0h11cMfdMw21g";

//--------------------------------------------------
// common config below this line, no need to change
const IndexPage = () => ( <Redirect goto_url={url} /> );
export default IndexPage;

